import { CaretLeft } from 'phosphor-react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import React, { useState, useEffect } from 'react';
import { createSearchParams, Link, useNavigate } from 'react-router-dom';
import { encodeData } from 'Utils/encodeBase64';
import gb_main from 'assets/images/gbklogo.svg';
import { useSelector } from 'react-redux';
import { IsB2BLogged, IsB2BRoute } from 'B2B/Utils/IsB2B';
import rightArrow from '../../assets/icons/next.png';
import './MultiLevelSideNav.css';

/**
 *
 * @param root0
 * @param root0.data
 * @param root0.sideNavState
 * @param root0.sideNavHandler
 * @param root0.setSideNavState
 * @param root0.ToggleSidebar
 */
function MultilevelSideNav({ data, sideNavState, ToggleSidebar, monetizeData }) {
  const [currentMenus, setCurrentMenus] = useState(null);
  const [previousStack, setPreviousStack] = useState([]);
  const [previousCat, setPreviousCat] = useState(null);
  const [activeCat, setActiveCat] = useState(null);
  const navigate = useNavigate();
  const isB2BRoute = IsB2BRoute();

  const [activeAccordionItem, setActiveAccordionItem] = useState([]);

  const isB2BAllowedState = useSelector((state) => state?.storeConfig?.isB2BAllowed);

  const handleAccordionOnchange = (e) => {
    setActiveAccordionItem(e);
  };

  // Monetize chekcing to navigate brandstore
  const navigateToPage = (item) => {
    const monetizeDataArray = monetizeData;
    const filteredBrands = monetizeDataArray.filter((brands) => brands.title === item.name);
    filteredBrands.forEach((brands) => {
      if (brands.is_monetized === '1') {
        navigate({
          pathname: `/brand-store/${brands.url_key}/${brands.entity_id}`,
          search: createSearchParams({
            filters: encodeData({
              brand_name: {
                in: [brands.entity_id],
              },
            }),
            firstFilters: encodeData({
              brand_name: {
                in: [brands.entity_id],
              },
            }),
          }).toString(),
        });
      } else {
        navigate({
          pathname: `/product-listing/${brands.url_key}`,
          search: createSearchParams({
            filters: encodeData({
              brand_name: {
                in: [brands.entity_id],
              },
            }),
            firstFilters: encodeData({
              brand_name: {
                in: [brands.entity_id],
              },
            }),
          }).toString(),
        });
      }
    });
  };

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
    });
    setCurrentMenus(() => {
      function addIncludeInMenu(obj) {
        /* eslint-disable no-param-reassign */
        obj.include_in_menu = 1;
        if (obj?.children?.length) {
          obj.children.forEach((child) => {
            addIncludeInMenu(child);
          });
        }
      }
      if (data.length) {
        data?.forEach((data1) => {
          if (data1.name === 'BRANDS') {
            const { children: subBrands } = data1;
            const filteredSubBrands = subBrands?.filter((value) => value?.children.length !== 0);
            data1.children = filteredSubBrands;
          }
        });

        addIncludeInMenu(data.at(-1));
      }
      return data;
    });
  }, [data]);

  const renderMenuItems = (data1) => (
    <div className='multisidenav'>
      <Accordion allowZeroExpanded={() => true} onChange={handleAccordionOnchange}>
        <ul className='multisidenavmenu'>
          {data1.children
            ? data1.children.map((item, index) =>
                item?.children && item?.children.length
                  ? item.include_in_menu === 1 && (
                      <AccordionItem uuid={item.name.replace(/\s/g, '')}>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <li className=''>
                              {item.name}
                              <span className='nav-arr brnd'>
                                <img
                                  src={rightArrow}
                                  alt='right-arrow'
                                  className={
                                    activeAccordionItem[0] === item.name.replace(/\s/g, '')
                                      ? 'rotate-negative-90  rightArr'
                                      : 'rotate-90 rightArr'
                                  }
                                />
                              </span>
                            </li>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <ul className='accordion-panel-ul'>
                            {item.children.map((child) => (
                              <li
                                role='button'
                                onClick={() => {
                                  const activeCatName = activeCat?.name.toLowerCase();

                                  const baseUrl =
                                    activeCatName === 'brands' ||
                                    activeCatName === 'feature brands' ||
                                    activeCatName === 'exclusive' ||
                                    activeCatName === 'popular brands'
                                      ? 'brands'
                                      : activeCatName === 'community'
                                      ? 'category/community'
                                      : 'product-listing';

                                  if (baseUrl === 'brands') {
                                    navigateToPage(child);
                                  } else {
                                    navigate({
                                      pathname: `/${baseUrl}/${child.url_path}`,
                                      search: createSearchParams({
                                        filters: encodeData({
                                          category_uid: {
                                            in: [child.uid],
                                          },
                                        }),
                                        firstFilters: encodeData({
                                          category_uid: {
                                            in: [child.uid],
                                          },
                                        }),
                                      }).toString(),
                                    });
                                  }

                                  ToggleSidebar();
                                }}
                              >
                                {child.name}
                              </li>
                            ))}
                          </ul>
                        </AccordionItemPanel>
                      </AccordionItem>
                    )
                  : item.include_in_menu === 1 && (
                      <span
                        role='button'
                        tabIndex={0}
                        key={index.toString()}
                        onClick={() => {
                          ToggleSidebar();
                          if (item?.name.toLowerCase() === 'community')
                            return navigate('category/community');
                          if (previousCat?.name.toLowerCase() === 'brands') {
                            navigateToPage(item);
                          } else {
                            navigate({
                              pathname: `/product-listing/${item.url_path}`,
                              search: createSearchParams({
                                filters: encodeData({
                                  category_uid: {
                                    in: [item.uid],
                                  },
                                }),
                                firstFilters: encodeData({
                                  category_uid: {
                                    in: [item.uid],
                                  },
                                }),
                              }).toString(),
                            });
                          }
                        }}
                      >
                        <li>
                          {item.name}
                          <span className='nav-arr'>
                            <img src={rightArrow} alt='right-arrow' className='rightArr' />
                          </span>
                        </li>
                      </span>
                    ),
              )
            : data1.map((item, index) =>
                item.include_in_menu === 1 ? (
                  <div
                    role='button'
                    tabIndex={0}
                    key={index.toString()}
                    onClick={() => {
                      if (!(isB2BRoute && !IsB2BLogged())) {
                        if (!item?.children?.length) {
                          navigate(`category/${item?.url_path}`);
                          ToggleSidebar();
                          return;
                        }
                        if (item.name.toLowerCase() === 'community')
                          return navigate(`category/community`);
                        previousStack.push(data1);
                        if (activeCat) {
                          setPreviousCat(() => activeCat);
                          setActiveCat(() => item);
                        } else {
                          setActiveCat(() => item);
                        }
                        setPreviousStack(previousStack);
                        setCurrentMenus(item);
                      }
                    }}
                  >
                    <Link className='cursor-none'>
                      <li className=''>
                        {item.name}
                        <span className='nav-arr'>
                          <img src={rightArrow} alt='right-arrow' className='rightArr' />
                        </span>
                      </li>
                    </Link>
                  </div>
                ) : (
                  ' '
                ),
              )}

          {activeCat?.name?.toLowerCase() === 'brands' && (
            <li
              role='button'
              onClick={() => {
                navigate('/brands');
                ToggleSidebar();
              }}
            >
              {' '}
              All Brands
            </li>
          )}

          {!activeCat && !previousCat && (IsB2BLogged() || IsB2BRoute()) && (
            <Link
              to='/product-listing/all-product'
              onClick={(e) => (!isB2BRoute || IsB2BLogged() ? ToggleSidebar() : e.preventDefault())}
            >
              <li className=''>
                bulk savings
                <span className='nav-arr brnd'>
                  <img src={rightArrow} alt='right-arrow' className='rightArr' />
                </span>
              </li>
            </Link>
          )}

          {!activeCat && !previousCat && !IsB2BRoute() && !IsB2BLogged() && isB2BAllowedState && (
            <Link to='/business' onClick={ToggleSidebar}>
              <li className=''>
                PROFESSIONAL
                <span className='nav-arr brnd'>
                  <img src={rightArrow} alt='right-arrow' className='rightArr' />
                </span>
              </li>
            </Link>
          )}

          {!activeCat && !previousCat && !IsB2BLogged() && !IsB2BRoute() && (
            <Link
              to='/academy/samerkhouzami'
              onClick={(e) => (!isB2BRoute ? ToggleSidebar() : e.preventDefault())}
            >
              <li className=''>
                ACADEMY
                <span className='nav-arr brnd'>
                  <img src={rightArrow} alt='right-arrow' className='rightArr' />
                </span>
              </li>
            </Link>
          )}
        </ul>
      </Accordion>

      {activeCat?.name?.toLowerCase() === 'brands' ? (
        ''
      ) : activeCat?.name.toLowerCase() ===
        'just launched' ? null : activeCat?.name.toLowerCase() ===
        'feature brands' ? null : activeCat?.name.toLowerCase() ===
        'exclusive' ? null : activeCat?.name.toLowerCase() ===
        'popular brands' ? null : activeCat ? (
        <button
          type='button'
          onClick={() => {
            ToggleSidebar();
            if (activeCat?.name.toLowerCase() === 'brands') navigate('/brands');
            if (activeCat?.name.toLowerCase() === 'just launched') navigate('/brands');
            if (activeCat?.name.toLowerCase() === 'feature brands') navigate('/brands');
            if (activeCat?.name.toLowerCase() === 'exclusive') navigate('/brands');
            if (activeCat?.name.toLowerCase() === 'popular brands') navigate('/brands');
            else
              navigate(
                `/product-listing/${activeCat?.url_path}?filters=${encodeData({
                  category_uid: {
                    in: [activeCat.uid],
                  },
                })}?firstFilter=${encodeData({
                  category_uid: {
                    in: [activeCat.uid],
                  },
                })}`,
              );
            if (activeCat?.name.toLowerCase() === 'community') navigate('/category/community');
            else if (!previousCat) {
              navigate(
                {
                  pathname: `/category/${activeCat?.url_path}`,
                  search: createSearchParams({
                    filters: encodeData({
                      category_uid: {
                        in: [activeCat.uid],
                      },
                    }),
                    firstFilters: encodeData({
                      category_uid: {
                        in: [activeCat.uid],
                      },
                    }),
                  }).toString(),
                },
                {
                  preventScrollReset: true,
                  replace: true,
                },
              );
            } else {
              navigate(
                {
                  pathname: `/product-listing/${activeCat?.url_path}`,
                  search: createSearchParams({
                    filters: encodeData({
                      category_uid: {
                        in: [activeCat.uid],
                      },
                    }),
                    firstFilters: encodeData({
                      category_uid: {
                        in: [activeCat.uid],
                      },
                    }),
                  }).toString(),
                },
                {
                  preventScrollReset: true,
                  replace: true,
                },
              );
            }
          }}
          className='mside-btn whiteCta'
        >
          ALL {activeCat?.name}
        </button>
      ) : null}

      <div className='m-contact justify-content-between'>
        <div className='m-con-left'> </div>
      </div>
    </div>
  );

  return (
    currentMenus && (
      <div style={{ width: sideNavState ? '250px' : '0' }} className='multilevelSideNav'>
        {previousStack.length ? (
          <Link
            to='#'
            onClick={() => {
              const prevState = previousStack.pop();
              if (activeCat) {
                setPreviousCat(null);
                setActiveCat(previousCat);
              } else {
                setActiveCat(null);
              }
              setPreviousStack(previousStack);
              setCurrentMenus(prevState);
            }}
          >
            <span className='sideback'>
              <CaretLeft size={30} weight='thin' />
              <span className='side-back'>BACK</span>
            </span>
          </Link>
        ) : (
          <strong className='m-logo'>
            <a href='/' onClick={ToggleSidebar}>
              <img src={gb_main} alt='Glamourbook eCommerce' className='m-logo-img' />
            </a>
          </strong>
        )}

        {renderMenuItems(currentMenus)}
      </div>
    )
  );
}

export default MultilevelSideNav;
