import { gql } from '@apollo/client';
import { IsB2BLogged } from 'B2B/Utils/IsB2B';

export const BANNER_SLIDER = gql`
  query BannerSlider($page_type: String!, $type: String!) {
    BannerSlider(page_type: $page_type, type: $type) {
      banners {
        brand_category_uid
        banner_id
        alt_text
        link
        resource_type
        video_type
        resource_path
        resource_path_mobile
        resource_path_poster
        title
        __typename
        page_mapping_ids
        additional_information
        look_of_day_sku
        page_identifier
      }
      look_of_day_sku
      faq_banner
      title
      is_show_title
      additional_information
      discover
      product_ids
      slider_type
      slider_id
      page_type
      page_mapping_ids
      __typename
    }
  }
`;

export const GET_URL_PATH = gql`
  query categoryList($category_uid: String!) {
    categoryList(filters: { category_uid: { eq: $category_uid } }) {
      url_path
    }
  }
`;

export const BRAND_BANNER_SLIDER = gql`
  query BannerSlider($page_type: String!, $type: String!, $brand_id: Int) {
    BannerSlider(page_type: $page_type, type: $type, brand_id: $brand_id) {
      banners {
        banner_id
        alt_text
        link
        resource_type
        video_type
        resource_path
        resource_path_mobile
        resource_path_poster
        title
        __typename
        page_mapping_ids
        additional_information
        look_of_day_sku
      }
      look_of_day_sku
      faq_banner
      title
      is_show_title
      additional_information
      discover
      product_ids
      slider_type
      slider_id
      page_type
      __typename
      slider_id
    }
  }
`;

export const CATEGORY_LIST = gql`
  query categoryList($category: String!) {
    categoryList(filters: { url_path: { eq: $category } }) {
      id
      url_path
      description
      is_professional_store
    }
  }
`;

export const CATEGORY_SLIDER = gql`
  query BannerSlider($category_id: Int!) {
    BannerSlider(page_type: "category_page", category_id: $category_id, type: "web") {
      banners {
        banner_id
        alt_text
        link
        resource_type
        video_type
        resource_path
        resource_path_mobile
        resource_path_poster
        title
        __typename
        page_mapping_ids
        additional_information
        page_identifier
      }
      faq_banner
      look_of_day_sku
      title
      is_show_title
      additional_information
      discover
      product_ids
      slider_type
      slider_id
      page_type
      __typename
    }
  }
`;

export const CAT_BEST_SELLER = gql`
  query BestSellers($catId: String!) {
    products(filter: { category_id: { eq: $catId }, best_seller: { eq: "1" } }) {
      aggregations {
        attribute_code
        count
        label
        options {
          label
          value
          count
        }
      }
      total_count
      items {
        __typename
        uid
        labels
        name
        sku
        special_price
        best_seller
        free_product_available
        only_x_left_in_stock
        rating_summary
        offer_rules {
          discount
          type
          name
          show_on_product
        }
        brand_label
        image {
          sizes {
            image_id
            url
            width
            height
          }
        }

        thumbnail {
          sizes {
            image_id
            url
            width
            height
          }
          url
          label
        }
        ... on ConfigurableProduct {
          configurable_options {
            attribute_id
            attribute_code
            values {
              label
            }
          }
        }
        price_range {
          minimum_price {
            regular_price {
              value
            }
            discount {
              amount_off
              percent_off
            }
            final_price {
              value
            }
          }
          maximum_price {
            regular_price {
              value
            }
            discount {
              amount_off
              percent_off
            }
            final_price {
              value
            }
          }
        }
      }
    }
  }
`;

export const BUY_AGAIN_PRODUCTS = gql`
  query buyAgainProducts {
    buyAgainProducts {
      items {
        child_details {
                attribute_uid
                parent_sku
              }
        __typename
        swatch_data {
          label
          attribute_code
          type
          value
          __typename
        }
        size_weight_simple_product {
          label
          code
          __typename
        }
        size_volume_simple_product {
          label
          code
          __typename
        }
        uid
        labels
        name
        sku
        ${IsB2BLogged() ? `gst_tax` : ``}
        special_price
        best_seller
        free_product_available
        only_x_left_in_stock
        stock_status
        rating_summary
        offer_rules {
          discount
          type
          name
          show_on_product
        }
        ${
          IsB2BLogged
            ? `
          price_tiers {
            discount {
              amount_off
              percent_off
            }
            final_price {
              value
              currency
            }
            quantity
          }`
            : ``
        }
        brand_label
        thumbnail {
          sizes {
            image_id
            url
            width
            height
          }
          url
          label
        }
        ... on ConfigurableProduct {
          configurable_options {
            attribute_code
            label
            values {
              label
              value_index
              swatch_data {
                value
                __typename
              }
            }
          }
          variants {
            product {
              brand_label
              name
              sku
              uid
              free_product_available
              offer_rules {
                discount
                type
                name
                show_on_product
              }
              stock_status
              short_description {
                html
                __typename
              }
              description {
                html
                __typename
              }
              key_ingredients
              direction_for_use
              country_of_manufacture
              manufacturer_details
              product_benefit
              side_effects_safety_warning_ca
              formulation
              finish
              preference
              stay
              spf
              coverage
              is_it_heat_sensitive
              shelf_life_in_months
              skin_type
              skin_concern
              skin_tone
              hair_type
              hair_concern
              fragrance_family
              top_note_in_fragrance
              middle_note_in_fragrance
              bottom_note_in_fragrance
              warranty
              special_price
              price_range {
                minimum_price {
                  discount {
                    percent_off
                    __typename
                  }
                  regular_price {
                    value
                    currency
                    __typename
                  }
                  final_price {
                    value
                    currency
                    __typename
                  }
                  __typename
                }
                __typename
              }
              image {
                label
                url
                __typename
              }
              small_image {
                label
                url
                __typename
              }
              media_gallery {
                url
                label
                ... on ProductImage {
                  label
                  role
                  sizes {
                    image_id
                    url
                    width
                    height
                  }
                }
                ... on ProductVideo {
                  video_content {
                    media_type
                    video_provider
                    video_url
                    __typename
                  }
                  __typename
                }
                __typename
              }
              swatch_image
              __typename
            }
            attributes {
              uid
              label
              code
              value_index
              __typename
            }
            __typename
          }
        }
        price_range {
          minimum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              value
              currency
            }
            regular_price {
              value
              currency
            }
          }
          maximum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              value
              currency
            }
            regular_price {
              value
              currency
            }
          }
        }
      }
    }
  }
`;

export const GET_PRODUCTS = gql`
  query GetProduct($value: [String!]) {
    products(filter: { sku: { in: $value } }) {
      aggregations {
        attribute_code
        count
        label
        options {
          label
          value
          count
        }
      }
      total_count
      items {
        child_details {
          attribute_uid
          parent_sku
        }
        __typename
        swatch_data {
          label
          attribute_code
          type
          value
          __typename
        }
        size_weight_simple_product {
          label
          code
          __typename
        }
        size_volume_simple_product {
          label
          code
          __typename
        }
        uid
        labels
        name
        sku
        gst_tax
        special_price
        best_seller
        free_product_available
        only_x_left_in_stock
        stock_status
        rating_summary
        offer_rules {
          discount
          type
          name
          show_on_product
        }
        ${
          IsB2BLogged
            ? `
          price_tiers {
            discount {
              amount_off
              percent_off
            }
            final_price {
              value
              currency
            }
            quantity
          }`
            : ``
        }
        brand_label
        thumbnail {
          sizes {
            image_id
            url
            width
            height
          }
          url
          label
        }
        ... on ConfigurableProduct {
          configurable_options {
            attribute_code
            label
            values {
              label
              value_index
              swatch_data {
                value
                __typename
              }
            }
          }
          variants {
            product {
              brand_label
              name
              sku
              uid
              free_product_available
              offer_rules {
                discount
                type
                name
                show_on_product
              }
              stock_status
              short_description {
                html
                __typename
              }
              description {
                html
                __typename
              }
              key_ingredients
              direction_for_use
              country_of_manufacture
              manufacturer_details
              product_benefit
              side_effects_safety_warning_ca
              formulation
              finish
              preference
              stay
              spf
              coverage
              is_it_heat_sensitive
              shelf_life_in_months
              skin_type
              skin_concern
              skin_tone
              hair_type
              hair_concern
              fragrance_family
              top_note_in_fragrance
              middle_note_in_fragrance
              bottom_note_in_fragrance
              warranty
              special_price
              price_range {
                minimum_price {
                  discount {
                    percent_off
                    __typename
                  }
                  regular_price {
                    value
                    currency
                    __typename
                  }
                  final_price {
                    value
                    currency
                    __typename
                  }
                  __typename
                }
                __typename
              }
              image {
                label
                url
                __typename
              }
              small_image {
                label
                url
                __typename
              }
              media_gallery {
                url
                label
                ... on ProductImage {
                  label
                  role
                  sizes {
                    image_id
                    url
                    width
                    height
                  }
                }
                ... on ProductVideo {
                  video_content {
                    media_type
                    video_provider
                    video_url
                    __typename
                  }
                  __typename
                }
                __typename
              }
              swatch_image
              __typename
            }
            attributes {
              uid
              label
              code
              value_index
              __typename
            }
            __typename
          }
        }
        price_range {
          minimum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              value
              currency
            }
            regular_price {
              value
              currency
            }
          }
          maximum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              value
              currency
            }
            regular_price {
              value
              currency
            }
          }
        }
      }
    }
  }
`;

export const GET_PRODUCTS_MEDIA_GALLERY = gql`
  query GetProduct($value: [String!]) {
    products(filter: { sku: { in: $value } }) {
      items {
        child_details {
          attribute_uid
          parent_sku
        }
        sku
        media_gallery {
          url
          label
          ... on ProductImage {
            label
            role
          }
          ... on ProductVideo {
            video_content {
              media_type
              video_provider
              video_url
            }
          }
          __typename
        }
      }
    }
  }
`;
